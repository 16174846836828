import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

import { getAppId } from '../axios/auth.js'  // 授权验证
import router404 from '@/components/router404'   //无权限页面
import Home from '../views/Pub/Home.vue'     // 主页
import store from '../store'


const routes = [
    {
        path: "/router404",
        name: 'router404',
        meta: {
            title: "无权限",
            auth: false,     //是否需要授权
            module: "",   //功能模块
        },
        component: router404,

    },
    {
        path: '/Home',
        name: 'Home',
        meta: {
            title: "",
            auth: false,     //是否需要授权 获取openid
            module: "主页",   //功能模块
        },
        component: Home
    },
    {
        path: '/UnitCheck',
        name: 'UnitCheck',
        meta: {
            title: "运单查询",
            auth: true,     //是否需要授权
            module: "运单查询",   //功能模块
        },
        component: () => import('../views/Pub/UnitCheck.vue')   //运单查询
    },
    {
        path: '/UnitListDetail',
        name: 'UnitListDetail',
        meta: {
            title: "运单详情",
            auth: false,     //是否需要授权
            module: "运单查询",   //功能模块
        },
        component: () => import('../views/Pub/UnitListDetail.vue')   //运单详情
    },
    {
        path: '/VehicleLocationPath',
        name: 'VehicleLocationPath',
        meta: {
            title: "车辆位置",
            auth: false,     //是否需要授权
            module: "运单查询",   //功能模块
        },
        component: () => import('../views/Pub/VehicleLocationPath.vue')   //车辆位置
    },
    {
        path: '/BangDing',
        name: 'BangDing',
        meta: {
            title: "手机绑定",
            auth: true,     //是否需要授权
            module: "手机绑定",   //功能模块
        },
        component: () => import('../views/Pub/BangDing.vue')   //手机绑定
    },
    {
        path: '/BDUser',
        name: 'BDUser',
        meta: {
            title: "用户绑定",
            auth: true,    
            module: "手机绑定",    
        },
        component: () => import('../views/Pub/BDUser.vue')   
    },
    {
        path: '/UnitList',
        name: 'UnitList',
        meta: {
            title: "我的运单",
            auth: true,     //是否需要授权
            module: "我的运单",   //功能模块
        },
        component: () => import('../views/Pub/UnitList.vue')   //我的运单
    },
    {
        path: '/FastSingln',
        name: 'FastSingln',
        meta: {
            title: "扫描签收",
            auth: true,     //是否需要授权
            module: "扫描签收",   //功能模块
        },
        component: () => import('../views/Pub/FastSingln.vue')   //扫描签收
    },
    {
        path: '/SignDetail',
        name: 'SignDetail',
        meta: {
            title: "扫描签收",
            auth: false,     //是否需要授权
            module: "扫描签收",   //功能模块
        },
        component: () => import('../views/Pub/SignDetail.vue')   //扫描签收
    },
    {
        path: '/FastOrder',
        name: 'FastOrder',
        meta: {
            title: "快速下单",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true
        },
        component: () => import('../views/Pub/FastOrder.vue')   //快速下单
    },
    {
        path: '/AddData',
        name: 'AddData',
        meta: {
            title: "新建联系人",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true,
        },
        component: () => import('../views/Pub/AddData.vue')   //新建联系人
    },
    {
        path: '/DataList',
        name: 'DataList',
        meta: {
            title: "联系人列表",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true,
 
        },
        component: () => import('../views/Pub/DataList.vue')   //联系人列表
    },
    {
        path: '/MapAddr',
        name: 'MapAddr',
        meta: {
            title: "地图址",
            auth: false,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true
        },
        component: () => import('../views/Pub/MapAddr.vue')   //地图址
    },
    {
        path: '/OrderList',
        name: 'OrderList',
        meta: {
            title: "下单记录",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
        },
        component: () => import('../views/Pub/OrderList.vue')   //下单记录
    },
    {
        path: '/OrderListDetail',
        name: 'OrderListDetail',
        meta: {
            title: "记录详情",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块

        },
        component: () => import('../views/Pub/OrderListDetail.vue')   //记录详情
    },
    {
        path: '/SelfCheck',
        name: 'SelfCheck',
        meta: {
            title: "自助查询",
            auth: false,     //是否需要授权
            module: "自助开票",   //功能模块

        },
        component: () => import('../views/Pub/SelfCheck.vue')   //自助开票
    },
    {
        path: '/SelfOrder',
        name: 'SelfOrder',
        meta: {
            title: "自助开票",
            auth: false,     //是否需要授权
            module: "自助开票",   //功能模块

        },
        component: () => import('../views/Pub/SelfOrder.vue')   //自助开票
    },
    {
        path: '/TrackCheck',
        name: 'TrackCheck',
        meta: {
            title: "在途查询",
            auth: false,     //是否需要授权
            module: "在途跟踪 ",   //功能模块

        },
        component: () => import('../views/Pub/TrackCheck.vue')   //自助开票
    },
    {
        path: '/TrackWay',
        name: 'TrackWay',
        meta: {
            title: "在途跟踪",
            auth: false,     //是否需要授权
            module: "在途跟踪",   //功能模块

        },
        component: () => import('../views/Pub/TrackWay.vue')   //在途跟踪
    },
    {
        path: '/CodeService',
        name: 'CodeService',
        meta: {
            title: "自助扫码",
            auth: false,     //是否需要授权
            module: "",   //功能模块

        },
        component: () => import('../views/Pub/CodeService.vue')   //自助扫码
    },
    {
        path: '/UploadReceipt',
        name: 'UploadReceipt',
        meta: {
            title: "补传回单",
            auth: false,     //是否需要授权
            module: "补传回单",   //功能模块

        },
        component: () => import('../views/Pub/UploadReceipt.vue')   //补传回单
    },
    {
        path: '/ScanTake',
        name: 'ScanTake',
        meta: {
            title: "扫码送货",
            auth: false,     //是否需要授权
            module: "扫码送货",   //功能模块

        },
        component: () => import('../views/Pub/ScanTake.vue')   //扫码送货
    },
    {
        path: '/DaiShouCheck',
        name: 'DaiShouCheck',
        meta: {
            title: "货款查询",
            auth: true,     //是否需要授权
            module: "货款领取",   //功能模块
        },
        component: () => import('../views/Pub/DaiShouCheck.vue')   //货款查询
    },
    {
        path: '/DaiShouTake',
        name: 'DaiShouTake',
        meta: {
            title: "货款领取",
            auth: true,     //是否需要授权
            module: "货款领取",   //功能模块
        },
        component: () => import('../views/Pub/DaiShouTake.vue')   //货款领取
    },
    {
        path: '/HuiKouCheck',
        name: 'HuiKouCheck',
        meta: {
            title: "返款查询",
            auth: true,     //是否需要授权
            module: "返款领取",   //功能模块
        },
        component: () => import('../views/Pub/HuiKouCheck.vue')   //返款查询
    },
    {
        path: '/HuiKouTake',
        name: 'HuiKouTake',
        meta: {
            title: "返款查询",
            auth: true,     //是否需要授权
            module: "返款领取",   //功能模块
        },
        component: () => import('../views/Pub/HuiKouTake.vue')   //返款查询
    },
    {
        path: '/8888/FastOrder',
        name: '/8888/FastOrder',
        meta: {
            title: "速鸿物流-快速下单",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true
        },
        component: () => import('../views/8888/FastOrder.vue')   //快速下单   定制
    },
    {
        path: '/8888/OrderList',
        name: '8888/OrderList',
        meta: {
            title: "下单记录",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
        },
        component: () => import('../views/8888/OrderList.vue')   //下单记录  定制
    },
    {
        path: '/8888/AddData',
        name: '8888/AddData',
        meta: {
            title: "新建联系人",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true,
        },
        component: () => import('../views/8888/AddData.vue')   //新建联系人 定制
    },
    {
        path: '/8888/DataList',
        name: '8888/DataList',
        meta: {
            title: "联系人列表",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true,
 
        },
        component: () => import('../views/8888/DataList.vue')   //联系人列表 定制
    },
    {
        path: '/10555/FastOrder',
        name: '/10555/FastOrder',
        meta: {
            title: "快速下单",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true
        },
        component: () => import('../views/10555/FastOrder.vue')   //快速下单   定制  10555
    },
    {
        path: '/10555/OrderList',
        name: '10555/OrderList',
        meta: {
            title: "下单记录",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
        },
        component: () => import('../views/10555/OrderList.vue')   //下单记录  10555
    },
    {
        path: '/10358/FastOrder',
        name: '/10358/FastOrder',
        meta: {
            title: "快速下单",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
            keepAlive: true
        },
        component: () => import('../views/10358/FastOrder.vue')   //快速下单   定制  10358
    },
    {
        path: '/10358/OrderList',
        name: '10358/OrderList',
        meta: {
            title: "下单记录",
            auth: true,     //是否需要授权
            module: "快速下单",   //功能模块
        },
        component: () => import('../views/10358/OrderList.vue')   //下单记录  10358
    }
]

const router = createRouter({
    //history: createWebHistory(), 
    history: createWebHashHistory(),
    routes
});

// 当一个导航触发时，全局的 before 钩子按照创建顺序调用。钩子是异步解析执行，此时导航在所有钩子 resolve 完之前一直处于等待中。
router.beforeEach((to, from, next) => {


	//页面设置companyid后 重置当前访问的companyid    企业id
	var companyid = to.query.companyid ? to.query.companyid : 0;
   
	 //设置页面标题
	 const title = (to.meta.title ? to.meta.title : '') 
	 document.title = title  
     let url = window.location.href;
	//  获取开通模块
	const module = to.meta.module

	// 判断是否为公众号模拟器环境
	const isWechat = () => {
		 return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
	}
    // 判断是否是本地环境
    const isurl = () => {
        // store.commit("openid", "oYsgc6ynutzcNG3LqSOB0642p0g0")
        return url.indexOf("localhost") > -1 || url.indexOf("http://") > -1
   }
   
	// 判断是否为公众号模拟器环境
	if (!isWechat()&&!isurl()) {
		window.location.href =
			`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${'wx4438d61b67502474'}`
	}
   


    let auth1=to.meta.auth
   //  token 是否正确
   if(to.path == '/router404'){
   
      next();
   }else{
    if (companyid != 0) {   
		getAppId(companyid,module,auth1).then(res =>{       // 微信网页授权     
        
			if(res){
               let openid=store.state.openid
			   console.log(to.meta)
               if(to.meta.auth){    //判断是否需要授权
                   if(openid){
                    
                     next();
                   }else{
                      let appid=store.state.appid
                      let local = window.location.href;
                       // 如果没有openid 则进行授权
                       window.location.href =
                      `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
                   }
               }else{
                   next();
               }
               
				
			 }else{
              
                next({ path: '/router404'}); 
             }	
		})
	}else{
      
        next({ path: '/router404'}); 
        
    }
   }
	
});




export default router
